import React from "react"
import { navigate } from "gatsby"
import SEO from "../components/SEO"
import { Pagination } from "../components/ui"
import Footer from "../components/footer"
import { ConsultationPopButton } from "../components/form-biz"
import "../styles/blog.less"
import {
  SolutionTopBanner,
  BlogCard,
  BlogCategory,
  BlogCategoryM,
} from "../components/block"
const loadText = require("src/utils").loadText
const static_data = loadText("blog")
export const Content = ({ blogs, categories, category, type }) => {
  console.log(type)
  return (
    <div
      className="blog-content block-middle"
      style={categories ? { maxWidth: "1240px" } : {}}
    >
      <div className="blog-content-wrapper page-middle">
        {/* <div className="only-mobile">
          {categories && (
            <BlogCategoryM categories={categories} active={category} />
          )}
        </div> */}
        {blogs.map(
          ({ author, title, excerpt, date, slug, thumbnail, categories }) => (
            <BlogCard
              author={author}
              thumbnail={
                thumbnail
                  ? thumbnail.localFile
                    ? thumbnail.localFile.publicURL
                    : ""
                  : ""
              }
              type={type}
              // categories={categories}
              title={title}
              excerpt={excerpt}
              date={date}
              id={slug}
            />
          )
        )}
      </div>
      <div style={{ marginTop: "56px" }} className="only-desktop">
        {categories && (
          <BlogCategory categories={categories} active={category} type={type} />
        )}
      </div>
    </div>
  )
}
const BottomBanner = () => (
  <div className="blog-bottomBanner">
    <div className="blog-bottomBanner-content full-bg">
      <div className="page-middle">
        <div className="font-36 semi-bold" style={{ maxWidth: "913px" }}>
          {static_data.bottomBanner.title}
        </div>
        <div className="mt-24 btnWrap">
          <ConsultationPopButton buttonClassName="white" source="blog">
            {static_data.bottomBanner.buttonText}
          </ConsultationPopButton>
        </div>
      </div>
    </div>
  </div>
)
const Blog = ({ pageContext }) => {
  const {
    data,
    pageNum,
    pageTotal,
    prefixSlug,
    prefixName,
    categories,
    noIndex,
    pageSize,
    category,
    type,
  } = pageContext
  // console.log("xxx", otype)
  // debugger
  const _title = static_data.topBanner.title
  const title = prefixName ? `${_title}: ${prefixName}` : _title
  return (
    <div className="blog">
      <SEO
        noIndex={noIndex}
        title={static_data.seo.title.replace(
          "{}",
          prefixName ? `| ${prefixName} ` : ""
        )}
        description={`${title}- ${static_data.seo.description}`}
      >
        {pageNum !== 1 && (
          <link
            rel="prev"
            href={`${process.env.GATSBY_HOST}${prefixSlug}/${
              pageNum > 2 ? `${pageNum - 1}/` : ""
            }`}
          />
        )}
        {pageNum !== pageTotal && (
          <link
            rel="next"
            href={`${process.env.GATSBY_HOST}${prefixSlug}/${pageNum + 1}/`}
          />
        )}
      </SEO>
      <SolutionTopBanner
        {...static_data.topBanner}
        title={title}
        bgPic={"/blog/topBanner-bg.svg"}
        height={520}
        bannerPic={{
          src: `/blog/topBanner-bg-m.svg`,
          style: {
            backgroundPosition: "top center",
          },
        }}
        pic={{
          src: "/blog/topBanner-pic.png",
          style: {
            bottom: 0,
            position: "absolute",
            right: "160px",
          },
        }}
        bgColor="linear-gradient(4deg,rgba(194,234,255,1) 0%,rgba(239,250,255,1) 100%)"
      />
      {/* <div style={{ display: "flex",}}> */}
      <Content
        blogs={data}
        categories={categories}
        category={category}
        type={type}
      />
      {/* </div> */}
      {pageTotal > pageSize && (
        <div className="blog-pagination">
          <Pagination
            pageNum={pageNum}
            total={pageTotal}
            pageSize={pageSize}
            onChange={page => {
              navigate(`${prefixSlug}/${page > 1 ? `${page}/` : ""}`)
            }}
          />
        </div>
      )}
      <BottomBanner />
      <Footer />
    </div>
  )
}

export default Blog
